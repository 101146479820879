import { computed } from 'vue'
import { useApi } from '@tenant/composables'
import { useQuery } from '@tanstack/vue-query'

export function useCountries(prefix = null) {
    const url = prefix ? `/api/${prefix}` : '/api'

    const { execute } = useApi(`${url}/countries`, 'GET')
    const countries = computed(() =>
        data.value
            ? data.value.map((country) => ({
                  iso: country.iso,
                  value: country.id,
                  label: country.name,
              }))
            : []
    )
    const { data } = useQuery({
        queryKey: ['countries'],
        queryFn: () => execute(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { countries, execute }
}
